import { ISerializedUserEntity } from "@till/till-auth.fed-types";
import {
  useQuery,
  UseQueryResult,
  useQueryClient,
} from "@tanstack/react-query";

import store from "src/utilities/store";
import { UNAUTHORIZED } from "src/utilities/HttpError/statuses";

interface UserType {
  userType: "superUser" | "staffUser" | "merchantUser";
  isSuperUser: boolean;
  isStaffUser: boolean;
  isMerchantUser: boolean;
  emailVerified?: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  preferences?: any;
}

export function useCurrentUser(
  handleError = false // handle error in hook
): UseQueryResult<ISerializedUserEntity & UserType> {
  const queryClient = useQueryClient();
  return useQuery(["/auth/user-session/user"], {
    keepPreviousData: true,
    onSuccess(data) {
      const toStore = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        profileImage: data.profileImage,
        tillStaff: data.tillStaff,
      };

      store.sessionStore.set("currentUserObj", JSON.stringify(toStore));
    },
    onError: (error: { statusCode: number }) => {
      if (handleError && error.statusCode === UNAUTHORIZED) {
        return;
      }

      const defaultOptions = queryClient.getDefaultOptions();
      if (defaultOptions.queries && defaultOptions.queries.onError) {
        defaultOptions.queries.onError(error);
      }
    },
    select(data: ISerializedUserEntity): ISerializedUserEntity & UserType {
      let userType: UserType["userType"];

      if (data.superAdmin) {
        userType = "superUser";
      } else if (data.tillStaff) {
        userType = "staffUser";
      } else {
        userType = "merchantUser";
      }

      return {
        ...data,
        userType,
        isSuperUser: userType === "superUser",
        isStaffUser: userType === "staffUser",
        isMerchantUser: userType === "merchantUser",
      };
    },
    refetchOnWindowFocus: false, // MIN-714 needed to hide successful email notification banner
  });
}
