import { styled } from "@mui/material/styles";

const PREFIX = "PageNotFound";

const classes = {
  line: `${PREFIX}-line`,
  frame: `${PREFIX}-fill`,
  error: `${PREFIX}-error`,
};

const Root = styled("svg")(({ theme }) => {
  return {
    [`& .${classes.frame}`]: {
      fill: "none",
      stroke: theme.palette.common.black,
      strokeWidth: 4,
    },
    [`& .${classes.line}`]: {
      fill: "none",
      stroke: theme.palette.common.black,
      strokeWidth: 1.47946,
    },
    [`& .${classes.error}`]: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      fill: theme.palette.primary.main,
    },
  };
});

export function PageNotFound(): JSX.Element {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="145px"
      height="112px"
      viewBox="0 0 145 112"
    >
      <path
        className={classes.frame}
        d="M30.8495 87.8088H27.2202C12.0894 87.8088 2 75.6359 2 60.6756C2 49.8835 8.50762 40.0447 18.3649 35.6572C19.7884 16.9416 35.8453 2 54.9659 2C63.1743 2 71.0886 4.69857 77.4419 9.62701C81.0256 8.26073 84.9912 7.51865 88.7864 7.51865C104.246 7.51865 117.393 18.9118 119.641 33.836C132.845 36.0369 142.549 47.2131 142.549 60.6756C142.549 75.6359 133.128 87.8088 117.909 87.8088H113.699"
      />
      <rect
        className={classes.frame}
        x="41.9455"
        y="33.068"
        width="60.6579"
        height="76.932"
        rx="8"
      />
      <rect
        className={classes.line}
        x="59.6989"
        y="79.6725"
        width="25.1509"
        height="1.47946"
        rx="0.739731"
      />
      <rect
        className={classes.line}
        x="65.6171"
        y="95.9458"
        width="14.0549"
        height="1.47946"
        rx="0.739731"
      />
      <rect
        className={classes.line}
        x="82.6308"
        y="95.9458"
        width="4.43839"
        height="1.47946"
        rx="0.739731"
      />
      <rect
        className={classes.line}
        x="58.9591"
        y="95.9458"
        width="2.95892"
        height="1.47946"
        rx="0.739731"
      />
      <rect
        className={classes.line}
        x="52.3016"
        y="87.0697"
        width="39.9455"
        height="1.47946"
        rx="0.739731"
      />
      <path
        className={classes.error}
        d="M59.3964 63.1299L70.0382 47.5429C70.24 47.2482 70.5034 46.9957 70.8108 46.8022C72.046 46.027 73.7035 46.3589 74.5122 47.5429L85.154 63.1299C85.4388 63.5477 85.5898 64.0359 85.5898 64.5331C85.5898 65.949 84.3934 67.0959 82.9163 67.0959H61.6327C61.1141 67.0959 60.6048 66.9511 60.169 66.6781C58.9339 65.9029 58.5877 64.3139 59.3964 63.1299ZM70.7954 53.7788C70.7954 52.9617 71.4578 52.2993 72.2749 52.2993C73.092 52.2993 73.7544 52.9617 73.7544 53.7788V58.2172C73.7544 59.0343 73.092 59.6966 72.2749 59.6966C71.4578 59.6966 70.7954 59.0343 70.7954 58.2172V53.7788ZM72.2749 64.1342C71.3872 64.1342 70.7954 63.5424 70.7954 62.6547C70.7954 61.7671 71.3872 61.1753 72.2749 61.1753C73.1626 61.1753 73.7544 61.7671 73.7544 62.6547C73.7544 63.5424 73.1626 64.1342 72.2749 64.1342Z"
      />
    </Root>
  );
}
