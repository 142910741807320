import { useRouter } from "next/router";
import { Button, Grid, Typography } from "@mui/material";
import { GetStaticProps } from "next";

import { PageNotFound } from "src/components/ColoredIcons/PageNotFound";
import { useCurrentUser } from "src/hooks/auth/useCurrentUser";
import { ErrorLayout } from "src/components/Layout/Page/Error";
import {
  CommonStaticProps,
  getStaticProps as commonGetStaticProps,
} from "src/utilities/getStaticProps";
import { useTillTranslations } from "src/hooks/i18n/useTillTranslations";

export default function NotFound(): JSX.Element {
  const { t } = useTillTranslations("ERROR_PAGES");
  const router = useRouter();

  const { data: user } = useCurrentUser(true);

  const handleClose = () => {
    if (user) {
      router.push("/dashboard");
    } else {
      router.push("/signin");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={3.5}
      sx={{ mt: 1 }}
    >
      <Grid item justifyContent="center" alignItems="center">
        <PageNotFound />
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={1}
        >
          <Grid item>
            <Typography
              component="h2"
              variant="h1"
              sx={{ lineHeight: "1.25rem" }}
            >
              {t("notFound.header")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              align="center"
              sx={(theme) => ({
                color: theme.palette.text.hint,
              })}
            >
              {t("notFound.description")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleClose}
          style={{ width: "17.5rem" }}
        >
          {t("cta.returnHome")}
        </Button>
      </Grid>
    </Grid>
  );
}

NotFound.getLayout = function Layout(page: JSX.Element): JSX.Element {
  return <ErrorLayout title="ERROR_PAGES.notFound.title">{page}</ErrorLayout>;
};

export const getStaticProps: GetStaticProps<CommonStaticProps> = async (
  context
) => {
  const componentKeys = [
    "ERROR_PAGES",
    "ACTIVE_SESSION_VIEW",
    "NOT_FOUND_VIEW",
    "APP_ENTRY_POINT",
  ];

  return commonGetStaticProps(context, componentKeys);
};
